<script>
import Layout from "../../layouts/horizontal";
import PageHeader from "@/components/page-header";
import { required } from "vuelidate/lib/validators";
import { parseErrors } from '../../../helpers'
import Swal from "sweetalert2";
import http from '@/explorer-client'
import { debounce } from "debounce"
import Multiselect from "vue-multiselect";
import axios from 'axios';


import {
   authComputed
} from "@/state/helpers";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect
  },
  data() {
    return {
      roles:[
        
      ],
      companies:[],
      selectedRoles:[],
      selectedCompanies:[],
      errors:[],
      userSaving: false,
      title: this.$t("pages.administration.users.title"),
      newuserModal: false,
      newItem: {
       
      },
      showmodal: false,
     
      editedItemId: null,
      submitted: false,
      editCompany: null,
      companySaving: false,
      currencies: null,
      editedItem: {
       
      },
      items:[],
      isBusy: false,
      currentPage: 1,
      perPage: 20,
      totalItems: 0,
      keyword: null,

      breadcrumbs: [
        
        {
          text: this.$t("pages.administration.title")
        },
        {
          text: this.$t("pages.administration.users.title"),
          active: true
        }
      ]
    };
  },
  validations () {
    if (this.newuserModal) {
        return {
          newItem: {
            Name: { required },
            Email: { required },
            Password: { required }
          }
        };
    } else {
        return {
          editedItem: {
            UserName: { required },
            Name: { required },
            Email: { required }
          }
        };
    }
},
  created(){
    this.load();
   
  },
  computed: {
    ...authComputed,
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage);
    },
    fxHedgeEnabled() {
      return this.isFxHedge || (this.editedItem  && this.editedItem.Roles && this.editedItem.Roles.find(x => x.Id === 'fx_hedge')) ? true : false;
    },
    isFxHedge() {
      return process.env.VUE_APP_HEDGE === 'yes';
    },
  },
  watch: {
    selectedRoles: {
      handler: function () {
        this.debounceLoadUsers();
      }
    },
    selectedCompanies: {
      handler: function () {
        this.debounceLoadUsers();
      }
    },
    currentPage: {
      handler: function () {
        this.loadUsers().catch(error => {
          console.error(error)
        })
      }
    }
  },
  methods: {
    onCompanyEdit() {
      this.editCompany = {...this.editedItem.Company};
      this.companySaving = false;
    },
    onCompanyNew() {
      this.editCompany = { Id: null, Name: '' };
      this.companySaving = false;
    },
    async saveCompany  () {
      if(this.editCompany.Name && !this.companySaving) {
        if(!this.editCompany.Id) {
          try {
            this.companySaving = true;
            const resp = await http.post(`data/Company/objects`, {
                Name: this.editCompany.Name
              });
           
            const newCompany = {
              Name: this.editCompany.Name,
              Id: resp.data.Id
            };
            this.companies = [newCompany,...this.companies];
            this.editedItem.Company = newCompany; 
            this.editCompany = null;
          } finally {
            this.companySaving = false;
          }
        } else {
          try {
            this.companySaving = true;
            await http.put(`data/Company/objects/` + this.editCompany.Id, {
              Name: this.editCompany.Name
            });
           
            const newCompany = {
              Name: this.editCompany.Name,
              Id: this.editCompany.Id,
            };
            this.companies = [newCompany,...this.companies.filter(x => x.Id !== newCompany.Id)];
            this.editedItem.Company = newCompany; 
            this.editCompany = null;
          } finally {
            this.companySaving = false;
          }
        }
      }
    },
    debounceLoadUsers: debounce(function () {
      this.loadUsers();
    }, 1500),
    async loadCurrencies()  {
      this.isLoadCurrencies = true;
      try {
        const resp = await http.get(`data/Currency/objects`, {
          $orderby: 'Name asc'
        });
        this.currencies = resp.data.Items;
      }
      finally {
        this.isLoadCurrencies = false;
      }
    },
    async load(){
      await this.loadRoles();
      await this.loadCompanies();
      await this.loadCurrencies();
      await this.loadUsers();
    },
    async loadCompanies()  {
      this.isBusy = true;
      try {
        const resp = await http.get(`data/Company/objects`, {
          $orderby: 'Name asc'
        });
        this.companies = resp.data.Items;
      }
      finally {
        this.isBusy = false;
      }
    },
    async loadRoles()  {
      this.isBusy = true;
      try {
        const resp = await http.get(`roles`);
        this.roles = resp.data;
      }
      finally {
        this.isBusy = false;
      }
    },
    async loadUsers()  {
     
      this.isBusy = true;

      let $filter = '';
      const roleIDs = this.selectedRoles.map(a => a.Id);
      let $roleFilter = '';
      roleIDs.forEach(roleId => {
        if($roleFilter) {
          $roleFilter += ' or ';
        }
        $roleFilter += `Identity/Roles/any(role: role/Role/Id eq '${roleId}')`;
      });

      if($roleFilter) {
        $roleFilter = '(' + $roleFilter + ')';
      } 

      $filter +=  $roleFilter;

      const companyIDs = this.selectedCompanies.map(a => a.Id);
      let $companyFilter = '';
      companyIDs.forEach(companyId => {
        if($companyFilter) {
          $companyFilter += ' or ';
        }
        $companyFilter += `Company/Id eq '${companyId}'`;
      });

      if($companyFilter) {
        $filter +=  ' and ';
      }

      if($companyFilter) {
        $companyFilter = '(' + $companyFilter + ')';
      } 

      $filter +=  $companyFilter;
    
      let p = {
        $search: this.keyword || null,
        $top: this.perPage,
        $skip: this.perPage * (this.currentPage - 1),
        $filter:  $filter || null
       // $filter: `Id eq '08a267bb-3542-49d7-a990-1f035dde86ec'`
        //selectedRoles: this.selectedRoles.map(a => a.id)
      };
      const resp = await http.get(`data/User/objects`, {
        params: p
      });
      const users = [...resp.data.Items];
      const usd = this.currencies.find(x => x.Name === 'USD');
      users.forEach(user => {
        if(user.DefaultCurrency?.Id) {
          user.DefaultCurrency = this.currencies.find(x => x.Id === user.DefaultCurrency?.Id) ?? usd;
        } else {
          user.DefaultCurrency = usd;
        }
        const ccyExposures = [];
        if(user.KeyCcyExposures) {
          user.KeyCcyExposures.forEach(currency => {
            const c = this.currencies.find(x => x.Id === currency.Id);
            if(c) {
              ccyExposures.push(c);
            }
          })
        }
        user.KeyCcyExposures = ccyExposures;
        user.HedgeBenchmark = user.HedgeBenchmark ?? 50;
        user.HedgeRiskToleranceMin = user.HedgeRiskToleranceMin ?? 0;
        user.HedgeRiskToleranceMax = user.HedgeRiskToleranceMax ?? 100;
      })
      this.items = users;
      this.totalItems = resp.data.Total;
      this.isBusy = false;  
    },
    hideNewItemModal() {
      this.processing = false;
      this.newuserModal = false;
      this.submitted = false;
      this.newItem = {};
      this.errors = [];
    },
    hideModal() {
      this.processing = false;
      this.showmodal = false;
      this.submitted = false;
      this.editedItem = {};
      this.errors = [];
      this.editCompany = null;
    },
    showNewUserModal(){
      this.processing = false;
      this.newuserModal = true;
      this.submitted = false;
      this.errors = [];
      this.newItem =  {
        Name: '',
        Email: '',
        Password: '',
        SendEmail: false
      };
    },
    showModal(user){

      const userCompany = user?.Company ? (this.companies.find(x => x.Id === user.Company.Id) || null) : null;
    
      this.processing = false;
      this.showmodal = true;
      this.submitted = false;
      this.errors = [];
      this.editedItem = user ? {
        ...user, 
        Roles: user.Roles.map(rId => this.roles.find(a => a.Id === rId)),
        Company: userCompany
      } : 
      {
        Company: null,
        Roles: [],
        LockoutStart: null,
        Password: null,
        Name: null,
        UserName: null,
        Email: null,
        LockoutEnd: null
      };
      this.editedItemId = user ? user.Id : null;
    },
    async handleNewUserSubmit() {
      this.submitted = true;
      this.errors = [];
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.userSaving = true;
        const registrationUrl = `${process.env.VUE_APP_PUBLIC_USR_MNGM_URL}users/fx-hedge`;
        try {
          await axios.post(registrationUrl, this.newItem);
          this.hideNewItemModal();
          this.loadUsers();
        } catch(error) {
          this.errors = parseErrors(error);
        }finally {
          this.userSaving = false;
        }
      }
    },
    handleSubmit() {
      this.submitted = true;
      this.errors = [];
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.userSaving = true;
        const dto = { 
          ...this.editedItem,
          Name: this.editedItem.Name,
          UserName:  this.editedItem.UserName,
          PhoneNumber: this.editedItem.PhoneNumber,
          Email: this.editedItem.Email,
          LockoutStart: this.editedItem.LockoutStart || null,
          LockoutEnd: this.editedItem.LockoutEnd  || null,
          Roles: (this.editedItem.Roles || []).map(x => x.Id),
          KeyCcyExposures: (this.editedItem.KeyCcyExposures || []).map(x => ({Id: x.Id})),
          Company: this.editedItem.Company?.Id ? { Id: this.editedItem.Company?.Id } : null,
          DefaultCurrency: this.editedItem.DefaultCurrency?.Id ? { Id: this.editedItem.DefaultCurrency?.Id } : null,
          Password: this.editedItem.Password
        };
        const req = !this.editedItemId ? http.post(`data/User/objects`, dto) : http.put(`data/User/objects/${this.editedItemId}`, dto);
        req.then(() => {
          this.hideModal();
          this.loadUsers();
          this.userSaving = false;
        })
        .catch(error => {
          this.errors = parseErrors(error);
          this.userSaving = false;
        });
      }
    },
    confirmDelete(item) {
      Swal.fire({
        title: this.$t("common.areYouSure"),
        text: this.$t("common.youCannotRevertOp"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("common.yesDelete")
      }).then(result => {
        if (result.value) {
         this.handleDelete(item);
        }
      });
    },
    handleDelete(item){
     http.delete(`data/User/objects/${item.Id}`).then(() => {
        this.hideModal();
          Swal.fire(this.$t("common.deleted"), this.$t("common.recWasDeleted"), "success");
          this.loadUsers();
      })
      .catch(error => {
        this.errors= parseErrors(error);
      });
    },
    getRoleName(roleId) {
      return this.roles.find(x => x.Id === roleId)?.Name || roleId;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" />
    <div class="row">
      <div class="col-12">
      <div class="email-leftbar card p-0 cardc8">
      <div class="card-content m-3">
        <div class="form-group" v-if="isFxHedge">
         <button type="button" class="btn btn-primary btn-block" @click="showNewUserModal">
          New User
         </button>
        </div>
        <div class="form-group">
          <div class="search-box">
            <div class="position-relative">
              <input type="text" class="form-control rounded" v-model="keyword" :placeholder='$t("common.search")' @keyup="debounceLoadUsers"/>
              <i class="mdi mdi-magnify search-icon"></i>
            </div>
          </div>
        </div>
        <div class="form-group">
          <multiselect 
            v-model="selectedRoles" 
            track-by="Id"
            label="Name"
            value="Id"
            placeholder="Roles"
            :options="roles" 
            :multiple="true">
          </multiselect>
        </div>
        <div class="form-group" v-if="!isFxHedge">
          <multiselect 
            v-model="selectedCompanies" 
            track-by="Id"
            label="Name"
            value="Id"
            placeholder="Companies"
            :options="companies" 
            :multiple="true">
          </multiselect>
        </div>    
      </div>
      </div>
        <div class="email-rightbar mb-2">
          <div class="card p-0 cardc8">
            <div class="card-content m-3">
            <div class="">
              <div class="form-group" v-if="!isFxHedge">
                <a href="javascript:void(0);" class="btn btn-secondary" @click="showModal(null)">
                  {{$t("common.newRecord")}}
                </a>
              </div>
              
              <div class="table-responsive mt-3" style="position: relative;">
                <table
                  class="table table-centered nowrap"
                  style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                >
                  <thead >
                    <tr>
                      <th style="width: 60px;max-width:60px;"></th>
                      <th>{{$t("tables.users.columns.userName")}}</th>
                      <th>{{$t("tables.users.columns.name")}}</th>
                      <th>{{$t("tables.users.columns.email")}}</th>
                      <th  v-if="!isFxHedge">{{$t("tables.users.columns.company")}}</th>
                      <th>{{$t("tables.users.columns.active")}}</th>
                      <th>{{$t("tables.users.columns.role")}}</th>
                      <th>{{$t("tables.users.columns.trialPeriodEnd")}}</th>
                      <th style="width: 60px;max-width:60px;"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                    <td>
                        <a
                          v-if="!item.isSystem"
                          href="javascript:void(0);"
                          class="mr-3 text-primary"
                          v-b-tooltip.hover
                          @click="showModal(item)"
                          
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>
                      </td>
                      <td>{{item.UserName}}</td>
                      <td>{{item.Name}}</td>
                      <td>{{item.Email}}</td>
                      <td  v-if="!isFxHedge">
                        <span v-if="item.Company">{{item.Company.DisplayValue}}</span>
                      </td>
                      <td>
                        <b-badge v-if="item.IsLocked" variant="warning">locked</b-badge>
                        <b-badge v-if="!item.IsLocked" variant="info">active</b-badge>
                      </td>
                      
                      <td>
                        <span v-for="roleId in item.Roles" :key="roleId" class="mr-1">
                          <b-badge v-if="roleId === 'administrator'"  variant="primary">{{  getRoleName(roleId) }}</b-badge>
                          <b-badge v-else variant="secondary">{{ getRoleName(roleId) }}</b-badge>
                        </span>
                       
                      </td>
                      <td>
                        <span v-if="item.LockoutStart">  {{item.LockoutStart | moment('L')}}</span>
                      
                      </td>
                      <td>
                        <a
                          v-if="!item.isSystem"
                          href="javascript:void(0);"
                          class="mr-3 text-danger"
                          :disabled="userSaving"
                          @click="confirmDelete(item)"
                          title="Delete"
                        >
                          <i class="fa fa-times font-size-18"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div style="position:absolute; left:0; right:0; top:0; bottom:0; background-color: rgba(255,255,255,0.5); text-align: center; padding-top:10%;"
                  v-if="isBusy">
                  <span>
                    <i class="fa fa-spin fa-spinner" style="font-size:3rem"></i>
                  </span>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col"><span class="text-muted" v-if="totalItems">{{$t("common.page")}} {{ currentPage }} {{$t("common.from")}} {{ totalPages }}</span></div>
                <div class="col text-right">
                  <b-pagination class="justify-content-center justify-content-sm-end m-0"
                    v-if="totalItems"
                    v-model="currentPage"
                    :total-rows="totalItems"
                    :per-page="perPage"
                    size="sm" />
                </div>
              </div>
            </div>
             </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <b-modal
      id="modal-1"
      size="md"
      v-model="showmodal"
      :title="editedItemId ? $t('common.editRecord') :  $t('common.newRecord')"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="handleSubmit" autocomplete="off">
         <b-alert
          variant="danger"
          class="mt-3"
          v-if="errors.length > 0"
          show
          dismissible
          >
          <ul>
            <li v-for="e in errors" :key="e">{{e}}</li>
          </ul>
        </b-alert>

         <div class="form-group row">
          <label class="col-lg-3 text-right" for="userName">{{$t('forms.userEdit.userName')}} [*]</label>
          <div class="col-lg-9">
            <input
              id="userName"
              v-model="editedItem.UserName"
              type="text"
              class="form-control"
              placeholder=""
              :class="{ 'is-invalid': submitted && $v.editedItem.UserName.$error }"
            />
            <div
              v-if="submitted && !$v.editedItem.UserName.required"
              class="invalid-feedback"
            >{{$t('forms.userEdit.userName-required')}}</div>
          </div>
          
        </div>
        <div class="form-group row">
          <label  class="col-lg-3  text-right" for="name">{{$t('forms.userEdit.name')}} [*]</label>
          <div class="col-lg-9">
            <input
              id="name"
              v-model="editedItem.Name"
              type="text"
              class="form-control"
              placeholder=""
              :class="{ 'is-invalid': submitted && $v.editedItem.Name.$error }"
            />
            <div
              v-if="submitted && !$v.editedItem.Name.required"
              class="invalid-feedback"
            >{{$t('forms.userEdit.name-required')}}</div>
          </div>
         
        </div>
        
        
     
        <div class="form-group row">
          <label class="col-lg-3  text-right" for="email">{{$t('forms.userEdit.email')}}  [*]</label>
          <div class="col-lg-9">
            <input
              id="email"
              v-model="editedItem.Email"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.editedItem.Email.$error }"
            />
            <div
              v-if="submitted && !$v.editedItem.Email.required"
              class="invalid-feedback"
            >{{$t('forms.userEdit.email-required')}}</div>
          </div>
          
         
        </div>

        <div class="form-group row" v-if="!isFxHedge">
          <label class="col-lg-3  text-right"  for="phoneNumber">{{$t('forms.userEdit.phoneNumber')}}</label>
          <div class="col-lg-9">
            <input
              id="phoneNumber"
              v-model="editedItem.PhoneNumber"
              type="text"
              class="form-control "
            />
          </div>
          
        </div>

        <div class="form-group row" >
          <label class="col-lg-3  text-right" for="LockoutStart">{{$t('forms.userEdit.trialPeriodEnd')}}</label>
          <div class="col-lg-9">
            <b-form-datepicker id="LockoutStart" v-model="editedItem.LockoutStart" 
            reset-button
            close-button></b-form-datepicker>
          </div>
         
        </div>

        <div class="form-group row">
          <label class="col-lg-3  text-right" for="LockoutEnd">{{$t('forms.userEdit.lockoutEnd')}}</label>
          <div class="col-lg-9">
            <b-form-datepicker id="LockoutEnd" v-model="editedItem.LockoutEnd" 
            reset-button
            close-button></b-form-datepicker>
          </div>
         
        </div>

        <div class="form-group  row" >
          <label class="col-lg-3  text-right">{{$t('forms.userEdit.roles')}}</label>
          <div class="col-lg-9">
            <multiselect 
              v-model="editedItem.Roles" 
              track-by="Id"
              label="Name"
              value="Id"
              placeholder="Roles"
              :options="roles" 
              :multiple="true">
            </multiselect>
          </div>
        </div>

       

        <!-- <div class="form-group  row" >
          <label class="col-lg-3  text-right">Base currency</label>
          <div class="col-lg-9">
            <multiselect 
            v-model="editedItem.DefaultCurrency" 
            track-by="Id"
            label="DisplayName"
            value="Id"
            placeholder="Base currency"
            :options="currencies" 
            :multiple="false">
          </multiselect>
          </div>
        </div> -->
        <div class="form-group row">
          <label class="col-lg-3  text-right" for="password">{{$t('forms.userEdit.password')}}</label>
          <div class="col-lg-9">
            <input
              id="password"
              v-model="editedItem.Password"
              type="password"
              name="password"
              class="form-control"
              autocomplete="new-password"
            />  
          </div>
        </div>
        <!-- <div v-if="fxHedgeEnabled">
          <div class="form-group  row">
            <label class="col-lg-3  text-right"></label>
            <div class="col-lg-9">
              <hr/>
              <strong v-if="fxHedgeEnabled">C8 HEDGE</strong>
             
            </div>
          </div>
          <div class="form-group  row" >
            <label class="col-lg-4  text-right">Hedge benchmark, %</label>
            <div class="col-lg-8">
              <input class="form-control" v-model.number="editedItem.HedgeBenchmark" type="number"/>
            </div>
          </div>

          <div class="form-group  row">
            <label class="col-lg-4  text-right">Risk tolerance/range for hedges, %</label>
            <div class="col-lg-8">
              <div class="d-flex justify-content-between">
                <input class="form-control" v-model.number="editedItem.HedgeRiskToleranceMin" type="number"/>
                <span style="width: 20px;"></span>
                <input class="form-control" v-model.number="editedItem.HedgeRiskToleranceMax" type="number"/>
              </div>
            </div>
          </div>
          <div class="form-group  row">
            <label class="col-lg-4  text-right">Key ccy exposures</label>
            <div class="col-lg-8">
              <multiselect 
                v-model="editedItem.KeyCcyExposures" 
                track-by="Id"
                label="DisplayName"
                value="Id"
                placeholder="Currencies"
                :options="currencies" 
                :multiple="true">
              </multiselect>
            </div>
          </div>
        </div> -->
        
       
        
       
        <div>
          <b-button class="ml-1 float-left" @click="hideModal">{{$t('forms.userEdit.closeButton-title')}}</b-button>
          <button type="submit" class="btn btn-success float-right" :disabled="userSaving">
            <i class="fa fa-spin fa-spinner" v-if="userSaving"></i>
            {{$t('forms.userEdit.saveButton-title')}}
          </button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->

     <!-- New User Modal -->
     <b-modal
      id="modal-1"
      size="md"
      v-model="newuserModal"
      :title="$t('common.newRecord')"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="handleNewUserSubmit" autocomplete="off">
         <b-alert
          variant="danger"
          class="mt-3"
          v-if="errors.length > 0"
          show
          dismissible
          >
          <ul>
            <li v-for="e in errors" :key="e">{{e}}</li>
          </ul>
        </b-alert>

        
        <div class="form-group row">
          <label  class="col-lg-3  text-right" for="name">{{$t('forms.userEdit.name')}} [*]</label>
          <div class="col-lg-9">
            <input
              id="name"
              v-model="newItem.Name"
              type="text"
              class="form-control"
              placeholder=""
              :class="{ 'is-invalid': submitted && $v.newItem.Name.$error }"
            />
            <div
              v-if="submitted && !$v.newItem.Name.required"
              class="invalid-feedback"
            >{{$t('forms.userEdit.name-required')}}</div>
          </div>
         
        </div>
        
        
     
        <div class="form-group row">
          <label class="col-lg-3  text-right" for="email">{{$t('forms.userEdit.email')}}  [*]</label>
          <div class="col-lg-9">
            <input
              id="email"
              v-model="newItem.Email"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': submitted && $v.newItem.Email.$error }"
            />
            <div
              v-if="submitted && !$v.newItem.Email.required"
              class="invalid-feedback"
            >{{$t('forms.userEdit.email-required')}}</div>
          </div>
          
         
        </div>

        <div class="form-group row">
          <label class="col-lg-3  text-right" for="password">{{$t('forms.userEdit.password')}}</label>
          <div class="col-lg-9">
            <input
              id="password"
              v-model="newItem.Password"
              type="password"
              name="password"
              class="form-control"
              autocomplete="new-password"
              :class="{ 'is-invalid': submitted && $v.newItem.Password.$error }"
            />  
            <div
              v-if="submitted && !$v.newItem.Password.required"
              class="invalid-feedback"
            >Password is required field</div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-lg-3  text-right">Send Email</label>
          <div class="col-lg-9">
            <b-form-checkbox v-model="newItem.SendEmail" switch class="mr-1" style="display:inline-block;">
            </b-form-checkbox>
          </div>
        </div>
       
       
       
        <div>
          <b-button class="ml-1 float-left" @click="hideNewItemModal">{{$t('forms.userEdit.closeButton-title')}}</b-button>
          <button type="submit" class="btn btn-success float-right" :disabled="userSaving">
            <i class="fa fa-spin fa-spinner" v-if="userSaving"></i>
            {{$t('forms.userEdit.saveButton-title')}}
          </button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>